<template>
  <div>
    <div class="d-flex align-center text-2xl mb-6">
      <span class="headline">Individuals</span>
      <v-spacer />
      <v-btn class="mt-3" color="primary" @click="openCreateModal">
        <v-icon>mdi-plus</v-icon>
        <span class="ml-2">Create</span>
      </v-btn>
    </div>
    <IndividualSearchBox :value="routeQueryFilter" @submit="onSearchSubmit" />
    <div v-if="loading">
      <v-card>
        <v-card-text>
          <v-container>
            <v-progress-circular class="mx-auto flex" indeterminate color="primary"></v-progress-circular>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <v-card v-else>
      <DataTableHeader show-export table-id="individual-table table" :headers="headers" @update:search="searchText = $event" />
      <v-data-table
        id="individual-table"
        data-has-actions="true"
        :headers="$filterHeaders(headers)"
        :options.sync="individualsTableOptions"
        :server-items-length="totalIndividuals"
        :items="individualList"
        :search="searchText"
      >
        <template v-slot:item.avatarUrl="{ item }">
          <Avatar class="my-1" :picture="item.avatarUrl" :abbr="generateAbbr(item.firstName, item.lastName)" />
        </template>
        <template v-slot:item.superConnectMe="{ item }">
          <v-icon v-if="item.superConnectMe" color="green">mdi-check-circle</v-icon>
          <v-icon v-else color="red">mdi-close-circle</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex">
            <v-btn icon color="gray" title="Event Registrations" @click.stop="showEventRegistrations(item)">
              <v-icon small>mdi-calendar</v-icon>
            </v-btn>
            <v-btn icon color="gray" @click.stop="editItem(item)">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.actions2="{ item }">
          <v-btn icon color="gray" class="mx-auto" @click.stop="deleteItem(item)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import CreateIndividualModal from '@/components/individual/CreateIndividualModal';
import EventRegistrationListModal from '@/components/event/EventRegistrationListModal';
import IndividualSearchBox from '@/components/individual/IndividualSearchBox';
import Avatar from '@/components/ui/Avatar';
import DataTableHeader from '@/components/ui/DataTableHeader';
import DataTableHeaderMixin from '@/mixins/DataTableHeader';
import { DEFAULT_DATE_FORMAT } from '@/helpers/constants';
import { stringify } from '@/helpers/api';

export default {
  name: 'IndividualList',
  components: { DataTableHeader, Avatar, IndividualSearchBox },
  data: () => ({
    headers: [
      { text: 'Edit', value: 'actions', sortable: false, width: '100px', align: 'center' },
      { text: '', value: 'avatarUrl', width: '50px', sortable: false },
      { text: 'First Name', value: 'firstName', hideable: true, show: true },
      { text: 'Last Name', value: 'lastName', hideable: true, show: true },
      { text: 'Email', value: 'email', hideable: true, show: true },
      { text: 'Company', value: 'companyIndividualsText', hideable: true, show: true },
      { text: 'City', value: 'location', hideable: true, show: true },
      { text: 'LinkedIn', value: 'linkedIn', hideable: true, show: false },
      { text: 'Super Connect Me', value: 'superConnectMe', width: '200px', align: 'center', hideable: true, show: true },
      { text: 'Updated Date', value: 'updatedAt', hideable: true, show: false },
      { text: 'Actions', value: 'actions2', sortable: false, width: '30px', align: 'center' },
    ],
    searchText: '',
    individualsTableOptions: {},
    totalIndividuals: 0,
    loading: false,
  }),
  computed: {
    ...mapState(['individuals']),
    individualList() {
      return this.individuals.map((individual) => ({
        ...individual,
        updatedAt: dayjs(individual.updated_at).format(DEFAULT_DATE_FORMAT),
        companyIndividualsText: individual.companyIndividuals.map((companyIndividual) => `${companyIndividual.company.name} (${companyIndividual.jobTitle})`).join(', '),
      }));
    },
    routeQueryFilter() {
      return this.$route.query?.filters ? JSON.parse(this.$route.query.filters) : {};
    },
  },
  created() {
    this.fetchIndividualsData(this.routeQueryFilter);
  },
  methods: {
    ...mapActions(['fetchIndividuals', 'deleteIndividual']),
    onSearchSubmit(filterData) {
      if (filterData) {
        if (this.$route.query.filters !== stringify(filterData)) {
          this.$router.replace({
            path: this.$route.path,
            query: {
              filters: stringify(filterData),
            },
          });
        }
      }
      this.fetchIndividualsData(filterData);
    },
    generateAbbr(firstName, lastName) {
      let abbr = '';
      if (firstName.length) {
        abbr = firstName[0];
      }
      if (lastName.length) {
        abbr = `${abbr}${lastName[0]}`;
      }
      return abbr;
    },
    deleteItem(item) {
      const modal = this.$showConfirmModal(null, {
        confirm: () => {
          this.deleteIndividual(item.id);
          modal.close();
        },
      });
    },
    showEventRegistrations(item) {
      this.$showModal(EventRegistrationListModal, { individual: item });
    },
    editItem(item) {
      this.$showModal(CreateIndividualModal, { individual: item });
    },
    openCreateModal() {
      this.$showModal(CreateIndividualModal);
    },
    async fetchIndividualsData(filters) {
      this.loading = true;
      // eslint-disable-next-line
      const { sortBy, sortDesc, page, itemsPerPage } = this.individualsTableOptions;

      const response = await this.fetchIndividuals({ pageSize: itemsPerPage, page, filters });
      this.totalIndividuals = response.total;
      this.loading = false;
    },
  },
  mixins: [DataTableHeaderMixin],
};
</script>
