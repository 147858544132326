var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center text-2xl mb-6"},[_c('span',{staticClass:"headline"},[_vm._v("Individuals")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary"},on:{"click":_vm.openCreateModal}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"ml-2"},[_vm._v("Create")])],1)],1),_c('IndividualSearchBox',{attrs:{"value":_vm.routeQueryFilter},on:{"submit":_vm.onSearchSubmit}}),(_vm.loading)?_c('div',[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-progress-circular',{staticClass:"mx-auto flex",attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)],1):_c('v-card',[_c('DataTableHeader',{attrs:{"show-export":"","table-id":"individual-table table","headers":_vm.headers},on:{"update:search":function($event){_vm.searchText = $event}}}),_c('v-data-table',{attrs:{"id":"individual-table","data-has-actions":"true","headers":_vm.$filterHeaders(_vm.headers),"options":_vm.individualsTableOptions,"server-items-length":_vm.totalIndividuals,"items":_vm.individualList,"search":_vm.searchText},on:{"update:options":function($event){_vm.individualsTableOptions=$event}},scopedSlots:_vm._u([{key:"item.avatarUrl",fn:function(ref){
var item = ref.item;
return [_c('Avatar',{staticClass:"my-1",attrs:{"picture":item.avatarUrl,"abbr":_vm.generateAbbr(item.firstName, item.lastName)}})]}},{key:"item.superConnectMe",fn:function(ref){
var item = ref.item;
return [(item.superConnectMe)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","color":"gray","title":"Event Registrations"},on:{"click":function($event){$event.stopPropagation();return _vm.showEventRegistrations(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-calendar")])],1),_c('v-btn',{attrs:{"icon":"","color":"gray"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)]}},{key:"item.actions2",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-auto",attrs:{"icon":"","color":"gray"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }