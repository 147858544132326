<template>
  <img v-if="!error && picture" :width="size" :height="size" :src="picture" class="avatar" @error="error = true" />
  <div v-else class="avatar avatar--abbr text-uppercase" :style="{ width: `${size}px`, height: `${size}px` }">{{ abbr }}</div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    picture: String,
    size: {
      type: String,
      default: '40',
    },
    abbr: String,
  },
  data() {
    return {
      error: false,
    };
  },
};
</script>
<style lang="scss">
.avatar {
  user-select: none;
  border-radius: 50%;
  overflow: hidden;

  &--abbr {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ebebeb;
    border-radius: 50%;
    font-weight: bold;
    font-size: 12px;
  }
}
</style>
