<template>
  <v-card class="mb-1">
    <v-expansion-panels v-model="open" tile flat accordion>
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-medium text-gray-800">Multi-Match</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="d-flex text-gray-800">
            <v-col v-for="(param, index) in searchParameters" :key="index" cols="12" sm="12" md="6" class="pb-0">
              <div class="font-weight-medium text-body-2 pt-1">{{ param.label }}</div>
              <div>
                <template v-if="param.type === 'text'">
                  <v-text-field class="text-body-2 search-input" v-model="searchParameterValues[param.name]" single-line hide-details dense @keyup.enter="apply"></v-text-field>
                </template>
                <template v-else-if="param.type === 'checkbox'">
                  <v-checkbox class="" v-model="searchParameterValues[param.name]" single-line hide-details dense></v-checkbox>
                </template>
                <template v-else-if="param.type === 'dropdown'">
                  <v-select
                    class="text-body-2 search-input"
                    v-model="searchParameterValues[param.name]"
                    :items="param.items"
                    item-value="value"
                    item-text="label"
                    hide-details
                    dense
                    clearable
                  ></v-select>
                </template>
              </div>
            </v-col>
          </v-row>
          <div class="mt-5">
            <v-btn color="primary" small @click="apply">Apply</v-btn>
            <v-btn class="ml-3" small @click="clear">Clear</v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'IndividualSearchBox',
  props: {
    value: Object,
  },
  data() {
    return {
      searchTextLocal: '',
      searchParameterValues: {
        firstName: null,
        lastName: null,
        email: null,
        location: null,
        superConnectMe: null,
      },
      searchParameters: [
        {
          name: 'firstName',
          label: 'First Name',
          type: 'text',
          value: null,
        },
        {
          name: 'lastName',
          label: 'Last Name',
          type: 'text',
          value: null,
        },
        {
          name: 'email',
          label: 'Email',
          type: 'text',
          value: null,
        },
        {
          name: 'location',
          label: 'City',
          type: 'text',
          value: null,
        },
        {
          name: 'superConnectMe',
          label: 'Super Connect Me',
          type: 'dropdown',
          items: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
          value: null,
        },
      ],
      open: null,
    };
  },
  created() {
    if (this.value && typeof this.value === 'object' && Object.keys(this.value)?.length > 0) {
      this.open = 0;
      Object.keys(this.value).forEach((itemName) => {
        const searchItem = this.searchParameters.find((p) => p.name === itemName);
        if (searchItem) {
          this.searchParameterValues[itemName] = this.value[itemName];
        }
      });
    }
  },
  methods: {
    ...mapMutations(['SET_SEARCH_FILTER_DATA']),
    filterData() {
      const queryString = {};
      const filledData = this.searchParameters.filter((i) => this.searchParameterValues[i.name] || this.searchParameterValues[i.name] === false);

      filledData.forEach((item) => {
        let value;
        if (item.type === 'text') {
          value = this.searchParameterValues[item.name].trim();
        } else if (item.type === 'dropdown') {
          value = this.searchParameterValues[item.name];
        }
        queryString[item.name] = value;
      });

      return queryString;
    },
    apply() {
      this.$emit('submit', this.filterData());
      this.SET_SEARCH_FILTER_DATA(this.filterData());
    },
    clear() {
      Object.keys(this.searchParameterValues).forEach((key) => {
        this.searchParameterValues[key] = null;
      });
    },
  },
};
</script>

<style lang="scss">
.search-input {
  border: solid 1px #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
</style>
