<template>
  <v-dialog v-model="dialog" persistent max-width="900px" @keydown.esc="cancel">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span class="headline">Registered Events</span>
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="mt-3 mb-5">
          <span class="font-weight-medium">Individual:</span>
          {{ individual.firstName }} {{ individual.lastName }} ({{ individual.email }})
        </div>
        <div class="mb-5">
          <v-divider />
          <div class="mt-5 font-weight-bold mb-3">Quick Register:</div>
          <v-row class="mb-4">
            <v-col>
              <autocomplete v-model="eventId" dense label="Event Name" hide-details item-text="name" item-value="id" :items="events" clearable></autocomplete>
            </v-col>
            <v-col>
              <v-checkbox v-model="optedIn" class="mt-0" label="Opted-In" hide-details></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox v-model="attended" class="mt-0" label="Attended" hide-details></v-checkbox>
            </v-col>
            <v-col>
              <v-btn :disabled="!eventId" color="primary" small @click="addRegistration">
                <v-icon dense>mdi-plus</v-icon>
                <span class="ml-2">Add</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />
        </div>
        <DataTableHeader :headers="headers" @update:search="searchText = $event" />
        <v-data-table dense :headers="headers" :items="eventRegistrations" :search="searchText">
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ml-3 px-5" @click="cancel">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from '@/helpers/constants';
import DataTableHeader from '@/components/ui/DataTableHeader';

export default {
  name: 'EventRegistrationListModal',
  components: { DataTableHeader },
  props: {
    individual: Object,
  },
  data() {
    return {
      dialog: true,
      eventRegistrations: [],
      searchText: '',
      headers: [
        { text: 'Event Name', value: 'event.name' },
        { text: 'City', value: 'event.location', hideable: true, show: true },
        { text: 'Date', value: 'event.formattedDate', hideable: true, show: true },
        { text: 'Opted-In', value: 'optedInText', hideable: true, show: true },
        { text: 'Attended', value: 'attendedText', hideable: true, show: true },
        { text: 'Actions', value: 'actions', sortable: false, width: '100px', align: 'end' },
      ],
      eventId: '',
      optedIn: false,
      attended: false,
    };
  },
  computed: {
    ...mapState(['events']),
  },
  async created() {
    this.fetchEvents();
    this.fetchData();
  },
  methods: {
    ...mapActions(['fetchIndividualEventRegistrations', 'fetchEvents', 'registerIndividualToEvent', 'deleteEventRegistration']),
    async addRegistration() {
      const response = await this.registerIndividualToEvent({ eventId: this.eventId, individualId: this.individual.id, optedIn: this.optedIn, attended: this.attended });
      if (response) {
        this.fetchData();
      }
    },
    async fetchData() {
      const response = await this.fetchIndividualEventRegistrations(this.individual.id);
      if (response) {
        this.eventRegistrations = response.map((item) => {
          item.event.formattedDate = dayjs(item.event.date).format(DEFAULT_DATE_FORMAT);
          item.optedInText = item.optedIn ? 'Yes' : 'No';
          item.attendedText = item.attended ? 'Yes' : 'No';
          return item;
        });
      }
    },
    async deleteItem(item) {
      const deleted = await this.deleteEventRegistration(item.id);
      if (deleted) {
        this.fetchData();
      }
    },
    cancel() {
      this.dialog = false;
      this.$modal.delayedClose();
    },
  },
};
</script>
